.content,
.content > div {
  @apply text-sm;

  /* & > *:not(:first-child) {
    @apply mt-4;
  }

  & > *:not(:last-child) {
    @apply mb-4;
  } */

  & > p:first-child,
  & > h2:first-child + p,
  & > h3:first-child + p {
    @apply text-base sm:text-lg;
  }

  h2,
  h3 {
    @apply text-xl;
  }

  p,
  li {
    @apply text-base sm:text-lg;
  }

  ul {
    @apply list-none pl-6;
  }

  li::marker {
    content: '•  ';
  }

  & > article {
    @apply mx-auto max-w-[37.5rem] rounded-2.5xl bg-off-white p-6;

    & p,
    & li {
      @apply my-2;
    }
  }

  & > article:not(:first-child),
  & > h2:not(:first-child),
  & > h3:not(:first-child),
  & > h4:not(:first-child) {
    @apply mt-8;
  }

  & > article:not(:last-child) {
    @apply mb-8;
  }

  .header + & {
    & > h3 {
      @apply title;
    }

    & > h4 {
      @apply title-sm;
    }
  }

  &:first-child,
  *:not(.header) + & {
    & > h2 {
      @apply title;
    }

    & > h3,
    & > h4 {
      @apply title-sm;
    }
  }
}
